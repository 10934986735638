export const colors = {
    FADE_PURPLE: '#8456F7',
    DARK_GRAY: '#333',
    FADE_GRAY: '#1F2237',
    GRAY: '#1B1E31',
    PURPLE: '#682abf',
    WHITE: '#FFF',
    FADE_WHITE: '#fff9',
    RED: '#E95979'
}
