import { useState, useEffect } from "react";
import {
    useConnectedWallet,
    useLCDClient,
} from "@terra-money/wallet-provider";
import { Flex, Text, Stack, Spacer, Popover, PopoverTrigger, Box, PopoverContent, PopoverHeader, PopoverCloseButton, PopoverBody, Link } from "@chakra-ui/react"
import { BasicModal } from './modal'
import Refract from './terra/refract'
import YLPClaimYield from './terra/ylp_claim_yield'
import RedeemAtMaturity from "./terra/redeem_at_maturity"
import { units } from "../constants/units.js";
import { links } from "../constants/links.js"
import { colors } from '../constants/style.js'
import { calc_liquidity_per_lp } from "../util/calcs";

export function Header({ lp, pt, yt, pool, vaultContext, forceUpdate, updated }) {

    const [claimableYield, setClaimableYield] = useState(0);
    const [ptSupply, setPtSupply] = useState(0);
    const [userYieldState, setUserYieldState] = useState({});
    const [vaultState, setVaultState] = useState({});
    const [vaultLpTotal, setVaultLpTotal] = useState(0);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const liquidityPerPt = "ptoken_l" in vaultContext.config ? Number(vaultContext.config.ptoken_l) : 0;
    const liquidityPerLP = calc_liquidity_per_lp(vaultContext.lpSupply, vaultContext.poolState);
    
    const poolK = "assets" in vaultContext.poolState ? vaultContext.poolState.assets[0].amount * vaultContext.poolState.assets[1].amount : 0;
    const tokenX = "assets" in vaultContext.poolState ? 
        "token" in vaultContext.poolState.assets[0].info ? vaultContext.poolState.assets[0].info.token.contract_addr : vaultContext.poolState.assets[0].info.native_token.denom
        :
        "";
    const tokenY = "assets" in vaultContext.poolState ? 
        "token" in vaultContext.poolState.assets[1].info ? vaultContext.poolState.assets[1].info.token.contract_addr : vaultContext.poolState.assets[1].info.native_token.denom
        :
        "";

    const swapAstroportLink = tokenX > "" ? links.terra_astroport + `swap?from=${tokenX}&to=${tokenY}` : "";
    const lpAstroportLink = "pool_address" in vaultContext.config ? links.terra_astroport + "pools/" + vaultContext.config.pool_address : "";

    const ptK = pool > '' && vaultContext.poolState.assets ? "1 PT (k=" + (liquidityPerPt * liquidityPerPt).toFixed(4) + "): " + (liquidityPerPt * vaultContext.poolState.assets[0].amount/Math.sqrt(poolK)).toFixed(4) + " " + vaultContext.tokenXSymbol + " + " + (liquidityPerPt * vaultContext.poolState.assets[1].amount/Math.sqrt(poolK)).toFixed(4) + " " + vaultContext.tokenYSymbol : "";

    const lpK = pool > '' && vaultContext.poolState.assets ? "1 LP (k=" + (liquidityPerLP * liquidityPerLP).toFixed(4) + "): " + (liquidityPerLP * vaultContext.poolState.assets[0].amount/Math.sqrt(poolK)).toFixed(4) + " " + vaultContext.tokenXSymbol + " + " + (liquidityPerLP * vaultContext.poolState.assets[1].amount/Math.sqrt(poolK)).toFixed(4) + " " + vaultContext.tokenYSymbol : "";

    const isMatured = "maturity_timestamp" in vaultContext.config ? new Date(vaultContext.config.maturity_timestamp * 1000) < new Date() : false;

    //wire up to connectedWallet
    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchYieldData = async () => {
            if (!connectedWallet) {
                console.log('wallet not connected')
                return;
            }

            if (connectedWallet.network.chainID.startsWith("phoenix")) {
                alert(`Please only execute this example on Testnet`);
                return;
            }

            if (!pool || pool === '') {
                return;
            }

            const pool_object = JSON.parse(pool);

            terra.wasm.contractQuery(pool_object.principal_token, { "token_info": {} }).then(info_response => {
                setPtSupply(info_response.total_supply);
            });

            terra.wasm.contractQuery(pool_object.vault_address, { "state": {} }).then(state_response => {
                setVaultState(state_response);
            });

            terra.wasm.contractQuery(pool_object.vault_address, { "holder": { "address": connectedWallet.terraAddress } }).then(state_response => {
                setUserYieldState(state_response);
            })
                .catch((err) => console.log("error", err));

            terra.wasm.contractQuery(pool_object.collateral_token, { "balance": { "address": pool_object.vault_address } }).then(balance_response => {
                setVaultLpTotal(balance_response.balance);
            });
        };

        fetchYieldData()
            .then()
            .catch((err) => console.log('errror', err))
    }, [connectedWallet, pool, terra, updated]);

    //downstream calc of fetchyielddata; want t,o avoid slamming chain w/ queries
    useEffect(() => {
        const calcYield = async () => {
            const vaultLiquidityTotal = liquidityPerLP * vaultLpTotal;

            if (ptSupply > 0 && vaultLpTotal > 0 && vaultLiquidityTotal > 0) {

                if (vaultLiquidityTotal > liquidityPerPt * ptSupply) {
                    const currentYield = (vaultLiquidityTotal - liquidityPerPt * ptSupply) - vaultState.previous_yield_balance;
                    const vaultYieldIndex = Number(vaultState.global_index) + currentYield / ptSupply;
                    const userYield = (vaultYieldIndex - userYieldState.index) * Number(userYieldState.yield_balance) + Number(userYieldState.pending_yield);
                    setClaimableYield(userYield > 10000 ? userYield : 0)
                }
            }
        };

        calcYield()
            .then()
            .catch((err) => console.log('errror', err))
    }, [liquidityPerLP, liquidityPerPt, ptSupply, vaultLpTotal, userYieldState.index, userYieldState.pending_yield, userYieldState.yield_balance, vaultState.previous_yield_balance, vaultState.global_index]);

    return (
        <Flex
            paddingX='10'
            paddingY='2'
            gap='50'
            borderRadius='20px'
            mb='5'
            backgroundColor={colors.GRAY}>

            <Stack direction='row' alignItems='center'>
                <Text color={colors.FADE_WHITE}>
                    LP
                </Text>
                {pool &&
                    <>
                        <Text>
                            {` ${lp / units.MILLION} `}
                        </Text>
                        <BasicModal openText={'Refract'} title={'Refract'}>
                            <Refract
                                pool={pool}
                                vaultContext={vaultContext}
                                forceUpdate={forceUpdate} />
                        </BasicModal>
                    </>
                }
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Text color={colors.FADE_WHITE}>
                    PT
                </Text>
                {pool &&
                    <>
                        <Text>
                            {` ${pt / units.MILLION} `}
                        </Text>
                        <BasicModal openText={'Redeem'} title={'Redeem'} disabled={!isMatured}>
                            <RedeemAtMaturity
                                pool={pool}
                                vaultContext={vaultContext}
                                forceUpdate={forceUpdate} />
                        </BasicModal>
                    </>
                }
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Text color={colors.FADE_WHITE}>
                    YT
                </Text>
                {pool &&
                    <>
                        <Text>
                            {` ${yt / units.MILLION} `}
                        </Text>
                        <BasicModal openText={'Claim'} title={'Claim'} disabled={claimableYield <= 0}>
                            <YLPClaimYield
                                pool={pool}
                                vaultContext={vaultContext}
                                forceUpdate={forceUpdate}
                                claimableYield={claimableYield} />
                        </BasicModal>
                    </>
                }
            </Stack>
            <Spacer />
            <Stack direction="row" alignItems='center'>
                {pool && 
                    <>
                        <Popover>
                            <PopoverTrigger>
                                <Box
                                role='button'
                                color={colors.FADE_WHITE}
                                children={pool ? JSON.parse(pool).display_name + " " + JSON.parse(pool).maturity_year + "-" + JSON.parse(pool).maturity_month : '-'}
                                />
                            </PopoverTrigger>
                            <PopoverContent bg={colors.FADE_GRAY} color={colors.FADE_WHITE} w="100%">
                                <PopoverHeader fontWeight='semibold'>Vault info</PopoverHeader>
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Stack spacing={0}>
                                        <Link href={swapAstroportLink} isExternal>{vaultContext.tokenXSymbol > "" ? "Swap between " + vaultContext.tokenXSymbol + "-" + vaultContext.tokenYSymbol : ""}</Link>
                                        <Link href={lpAstroportLink} isExternal>{vaultContext.tokenXSymbol > "" ? "Provide liquidity for " + vaultContext.tokenXSymbol + "-" + vaultContext.tokenYSymbol : ""}</Link>
                                        <Text>{ptK}</Text>
                                        <Text>{lpK}</Text>
                                    </Stack>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover> 
                    </>
                }
            </Stack>

        </Flex>
    )
}