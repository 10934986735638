import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from '../transaction_feedback'
import { Input, Stack, Text } from '@chakra-ui/react'
import { isNumber } from '../../util/validation'
import { units } from "../../constants/units.js"
import { DefaultSpinner } from '../spinner'
import { calc_liquidity_per_lp, calc_expected_zodiac_swap } from "../../util/calcs";

export default function Refract({ pool, vaultContext, forceUpdate }) {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [amount, setAmount] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    //context from props
    const vaultAddress = pool && pool !== '' ? JSON.parse(pool).vault_address : '';
    const collateralAddress = pool && pool !== '' ? JSON.parse(pool).collateral_token : '';
    const amountPlaceholder = vaultContext === {} ? 0 : vaultContext["lp"];

    const liquidityPerPt = "ptoken_l" in vaultContext.config ? Number(vaultContext.config.ptoken_l): 0;
    const liquidityPerLP = calc_liquidity_per_lp(vaultContext.lpSupply, vaultContext.poolState);

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)

            const buff = new Buffer.from(
                JSON.stringify({
                    "deposit_collateral": {}
                })
            );
            const binary_msg = buff.toString("base64");

            const amount_in = amount === "" ? amountPlaceholder / units.MILLION : amount; //in millions

            const deposit_collateral = new MsgExecuteContract(
                connectedWallet.walletAddress,
                collateralAddress,
                {
                    "send": {
                        "contract": vaultAddress,
                        "amount": String(Math.floor(amount_in * units.MILLION)),
                        "msg": binary_msg,
                    },
                },
            );

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    deposit_collateral
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText(
                "PT & YT minted:  " +
                Number(result.logs[0].eventsByType['wasm-zodiac_deposit']["plp_minted"][0]) / units.MILLION 
            );

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)
        forceUpdate();
    }, [connectedWallet, terra, amount, collateralAddress, vaultAddress, forceUpdate, amountPlaceholder]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="amount"
                        type="text"
                        value={amount}
                        placeholder={amountPlaceholder / units.MILLION}
                        onChange={e => isNumber(e.target.value) && setAmount(e.target.value)} />
                    {
                        <Stack direction='row'>
                            <Text>
                                {`expected PT & YT: `}
                            </Text>
                            <Text fontWeight='bold'>
                            {
                                calc_expected_zodiac_swap(amount, amountPlaceholder, liquidityPerLP, liquidityPerPt)
                            }
                            </Text>
                        </Stack>
                    }
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }
                    <DefaultButton onClick={sendTransaction}>Refract</DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet}
                targetWord={txText} />
        </>
    );
}