import { units } from "../constants/units.js";

export function calc_liquidity_per_lp(lp_supply = 0, pool_state) {
  if ("assets" in pool_state) {
    return Math.floor(Math.sqrt(Number(pool_state.assets[0]["amount"]) * Number(pool_state.assets[1]["amount"]))) / lp_supply
  }
  return 0
}

export function calc_expected_zodiac_swap(
  amount_swap_in_asset,
  amount_placeholder,
  liquidity_per_swap_in_asset,
  liquidity_per_swap_out_asset) {
  if (amount_swap_in_asset > 0) {
    return (amount_swap_in_asset * liquidity_per_swap_in_asset / liquidity_per_swap_out_asset).toFixed(4)
  } else {
    return (amount_placeholder / units.MILLION * liquidity_per_swap_in_asset / liquidity_per_swap_out_asset).toFixed(4)
  }
}

export function calc_days_since_created(creation_timestamp) {
  return (new Date() - new Date(creation_timestamp) * 1000) / (1000 * 60 * 60 * 24)
}
