import { Box, Heading } from "@chakra-ui/react"
import Cw20 from "../components/terra/cw20";
import AstroportCreatePair from "../components/terra/astroport_create_pair";
import AstroportLPSetup from "../components/terra/astroport_lp_setup";
import AstroportAutoTrades from "../components/terra/astroport_auto_trades";

export function StagingPage() {
  return (
    <Box>
      <Heading mb={5}>Staging</Heading>
      <Cw20 />
      <AstroportCreatePair />
      <AstroportLPSetup />
      <AstroportAutoTrades />
    </Box>
  )
}

