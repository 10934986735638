import './app.css'
import { Route, Routes } from 'react-router-dom'
import { RewardsPage as OsmosisRewardsPage } from './pages/osmosis/rewards'
import { StagingPage } from './pages/staging'
import { Box } from "@chakra-ui/react"
import { Navbar } from './components/navbar'
import { Dashboard } from './pages/dashboard'
import { CreateVault } from './pages/createvault'

function App() {
  return (
    <Box className="app">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/app" element={<Dashboard />} />
        <Route path="/createvault" element={<CreateVault />} />
        <Route path="/staging" element={<StagingPage />} />
        <Route path="osmosis">
          <Route path="rewards" element={<OsmosisRewardsPage />} />
        </Route>
      </Routes>
    </Box>
  )
}

export default App
