import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
import { DefaultButton } from './button'
import { colors } from "../constants/style"

export function BasicModal({ openText, title, children, disabled }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const isDisabled = disabled ? disabled : false;

    return (
        <>
            <DefaultButton isDisabled={isDisabled} onClick={onOpen}>{openText}</DefaultButton>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                scrollBehavior='inside'
                isCentered
                size='2xl'
                color={colors.DARK_GRAY}>
                <ModalOverlay
                    backdropFilter='blur(20px)' />
                <ModalContent
                    color={colors.WHITE}
                    backgroundColor={colors.FADE_GRAY}
                    boxShadow='rgb(0 0 0 / 40%) 0px 0px 33px 8px'>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {children}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}