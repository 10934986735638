import { Container, Heading, Text } from "@chakra-ui/react"
import ZodiacCreateVault from '../components/terra/zodiac_create_vault'
import AstroportCreatePLPPool from '../components/terra/astroport_create_plp_pool'
import ZodiacRegisterPLPPool from "../components/terra/zodiac_register_plp_pool"

export function CreateVault() {
    return (
        <Container maxW='lg'>
            <Heading mb='8'>
                Create Vault
            </Heading>
            <ZodiacCreateVault />
            <Text>Create vAMM PT-LP pool</Text> 
            <AstroportCreatePLPPool />
            <Text>Register PT-LP pool</Text>
            <ZodiacRegisterPLPPool />
        </Container>
    )
}