import {
    useConnectedWallet,
    UserDenied,
} from "@terra-money/wallet-provider";
import { LCDClient, MsgInstantiateContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import { isNumber } from '../../util/validation'
import metadata from "../../metadata.json";
import { TransactionFeedback } from '../transaction_feedback'
import { units } from '../../constants/units'
import { Input, Stack } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'

export default function Cw20() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [codeId, setCodeId] = useState(metadata.cw20_code_id);
    const [symbol, setSymbol] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)
            
            const transactionMsg = {
                msgs: [
                    new MsgInstantiateContract(
                        connectedWallet.walletAddress,
                        connectedWallet.walletAddress,
                        codeId,
                        {
                            name: symbol,
                            symbol: symbol,
                            decimals: 6,
                            initial_balances: [
                                {
                                    address: metadata.wallets[0].address,
                                    amount: "10000000",
                                },
                                {
                                    address: metadata.wallets[2].address,
                                    amount: "31000000",
                                },
                                {
                                    address: connectedWallet.walletAddress,
                                    amount: "101000000"
                                }
                            ],
                            mint: {
                                minter: connectedWallet.walletAddress,
                                cap: null,
                            },
                            marketing: null
                        },
                        { uluna: units.MILLION },
                        "cw20 token",
                    ),
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg)

            const terra = new LCDClient({
                URL: connectedWallet.network.lcd,
                chainID: connectedWallet.network.chainID,
            });

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText("The token's address is " + result.logs[0].eventsByType["instantiate"]["_contract_address"][0]);

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)
    }, [connectedWallet, codeId, symbol]);

    const handleCodeId = (value) => {
        if (isNumber(value)) {
            setCodeId(Number(value))
        }
    }

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack direction='row' mb={10}>
                        <Input
                            name="code id"
                            type="text"
                            value={codeId}
                            placeholder="code id of a cw20 token"
                            onChange={e => handleCodeId(e.target.value)}
                            width='auto' />
                        <Input
                            name="symbol"
                            type="text"
                            value={symbol}
                            placeholder="cw20 token symbol"
                            onChange={e => setSymbol(e.target.value)}
                            width='auto'
                        />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                        
                    <DefaultButton onClick={sendTransaction}>Init {symbol} cw20 with id {codeId}</DefaultButton>
                </Stack>
        )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet}
                targetWord={txText} />
        </>
    );
}