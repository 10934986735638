import {
    useConnectedWallet,
    UserDenied,
} from "@terra-money/wallet-provider";
import { isTxError, LCDClient, MnemonicKey, MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import metadata from "../../metadata.json";
import { TransactionFeedback } from '../transaction_feedback'
import { Input, Stack } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'

export default function AstroportLPSetup() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [tokenX, setTokenX] = useState('');
    const [pairAddress, setPairAddress] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();

    const fabricateLPMessage = (walletAddress, pairAddress, tokenX, amount) => {
        return new MsgExecuteContract(
            walletAddress,
            pairAddress,
            {
                "provide_liquidity": {
                    "assets": [
                        {
                            "info": { "token": { "contract_addr": tokenX } },
                            "amount": amount,
                        },
                        {
                            "info": { "native_token": { "denom": "uluna" } },
                            "amount": amount,
                        },
                    ]
                }
            },
            { uluna: amount }
        );
    };

    const fabricateAllowanceMessage = (walletAddress, pairAddress, tokenX, amount) => {
        return new MsgExecuteContract(
            walletAddress,
            tokenX,
            {
                "increase_allowance": {
                    "spender": pairAddress,
                    "amount": amount,
                }
            }
        );
    };

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)

            const terra = new LCDClient({
                URL: connectedWallet.network.lcd,
                chainID: connectedWallet.network.chainID,
            });

            //create wallets from metadata.json seeds
            const wallet1 = terra.wallet(new MnemonicKey({
                mnemonic: metadata.wallets[0].seed,
            }));
            const wallet3 = terra.wallet(new MnemonicKey({
                mnemonic: metadata.wallets[2].seed,
            }));

            //sign & broadcast test wallets LP txs
            const wallet1Txs = await wallet1.createAndSignTx({
                msgs: [
                    fabricateAllowanceMessage(wallet1.key.accAddress, pairAddress, tokenX, "10000000"),
                    fabricateLPMessage(wallet1.key.accAddress, pairAddress, tokenX, "10000000")
                ]
            });
            const res1 = await terra.tx.broadcast(wallet1Txs);
            if (isTxError(res1)) {
                throw new Error(
                    `store code failed. code: ${res1.code}, codespace: ${res1.codespace}, raw_log: ${res1.raw_log}`
                )
            }

            const wallet3Txs = await wallet3.createAndSignTx({
                msgs: [
                    fabricateAllowanceMessage(wallet3.key.accAddress, pairAddress, tokenX, "31000000"), fabricateLPMessage(wallet3.key.accAddress, pairAddress, tokenX, "31000000")
                ]
            });
            let res2 = await terra.tx.broadcast(wallet3Txs);
            if (isTxError(res2)) {
                throw new Error(
                    `store code failed. code: ${res2.code}, codespace: ${res2.codespace}, raw_log: ${res2.raw_log}`
                )
            }

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    fabricateAllowanceMessage(connectedWallet.walletAddress, pairAddress, tokenX, "101000000"),
                    fabricateLPMessage(connectedWallet.walletAddress, pairAddress, tokenX, "101000000"),
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)        
    }, [connectedWallet, tokenX, pairAddress]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="token x"
                        type="text"
                        value={tokenX}
                        placeholder="contract address of token x"
                        onChange={e => setTokenX(e.target.value)}/>
                    <Input
                        name="pair address"
                        type="text"
                        value={pairAddress}
                        placeholder="contract address of astroport pool"
                        onChange={e => setPairAddress(e.target.value)} />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                         
                    <DefaultButton onClick={sendTransaction}>Setup LPs </DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet} />
        </>
    );
}