import { DefaultButton } from "../../components/button";
import Tx from "../../components/osmosis/Tx";

export function RewardsPage() {
  return (
    <div>
      <div>Rewards</div>
      <DefaultButton>Claim Rewards</DefaultButton>
      <Tx />
    </div>

  )
}
