import { useState } from 'react'
import { DefaultButton } from './button'
import { Box, Link, Text } from "@chakra-ui/react"
import { colors } from '../constants/style'
import { links } from '../constants/links';

function ShowTxResult({ txResult, targetWord, connectedWallet }) {
    const [displayTxResult, setDisplayTxResult] = useState(false);
    const stringifiedTxResult = txResult ? JSON.stringify(txResult, null, 2) : ''
    const txResultTitle = targetWord ? targetWord : '';
    const txHash = txResult && "txhash" in txResult ? txResult.txhash : '';
    const finderUrl = connectedWallet && connectedWallet.network.chainID.startsWith("phoenix") ?  links.terra_finder_mainnet : links.terra_finder_testnet;


    return (
        <Box fontSize='sm' style={{ wordBreak: 'break-all' }}>
            <Text>{txResultTitle}</Text>
            <Link display='block' mb='2' href={finderUrl + "tx/" + txHash} isExternal>TxHash: {txHash.slice(0, 5) + "..." + txHash.slice(-5)}</Link>
            <DefaultButton onClick={() => setDisplayTxResult(v => !v)}>{displayTxResult ? 'Hide' : 'Display'}</DefaultButton>
            {displayTxResult && <Text >{stringifiedTxResult}</Text>}
        </Box>
    )
}

export function TransactionFeedback({ txResult, txError, connectedWallet, targetWord }) {

    return (
        <>
            {txResult && <ShowTxResult txResult={txResult} targetWord={targetWord} connectedWallet={connectedWallet} />}
            {txError && <Text fontSize='sm' color={colors.RED}>{txError}</Text>}

            {connectedWallet && !connectedWallet.availablePost && (
                <p>This connection does not support post()</p>
            )}
        </>
    )
}