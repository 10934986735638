import { ImArrowLeft, ImArrowRight } from 'react-icons/im'
import styles from './shapes.module.css';

export function TriangleBottomRight({ text }) {
    return (
        <div className={`${styles.triangle} ${styles['triangle-bottomright']}`}>
            <p className={styles.text}>
                {text}
            </p>
        </div>
    )
}

export function TriangleTopLeft({ text }) {
    return (
        <div className={`${styles.triangle} ${styles["triangle-topleft"]}`}>
            <p className={styles.text}>
                {text}
            </p>
        </div>
    )
}

export function Square({ text }) {
    return (
        <div className={styles.square}>
            {text}
        </div>
    )
}

export function ClickableArrowRight({ onClick }) {
    return (
        <ImArrowRight className={styles["clickable-arrow"]} onClick={onClick} />
    )
}

export function ClickableArrowLeft({ onClick }) {
    return (
        <ImArrowLeft className={styles["clickable-arrow"]} onClick={onClick} />
    )
}