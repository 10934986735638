import React, { useState, useEffect } from "react";
import { Box, Grid, GridItem, Heading, Link, Stack } from "@chakra-ui/react"
import { useConnectedWallet, useLCDClient } from "@terra-money/wallet-provider";
import { SidePanel } from '../components/side_panel'
import ZodiacEarlyExit from "../components/terra/zodiac_early_exit";
import { colors } from "../constants/style"
import { metadata } from "../constants/metadata";
import Vamm from '../components/terra/vamm';
import ZodiacPLPPool from '../components/terra/zodiac_plp_pool';
import { Header } from '../components/header'
import { TriangleTopLeft, TriangleBottomRight, Square } from '../components/shapes'
import { FaPlus } from 'react-icons/fa'
import { useViewStore } from '../hooks/view_state';
import { links } from "../constants/links";
import { BiLinkExternal } from 'react-icons/bi'

function Card({ title, children, url }) {
    return (
        <Box p='5' borderRadius='20px' backgroundColor={colors.GRAY}>
            <Stack direction="row">
                <Heading
                    fontSize='l'
                    mb='5'
                    textTransform='uppercase'>
                    {title}
                </Heading>
                {url > '' && (<Link href={url} isExternal><BiLinkExternal></BiLinkExternal></Link>)}
            </Stack>
            {children}
        </Box>
    )
}

export function Dashboard() {
    const [{ currentPool: pool },] = useViewStore();
    const [lp_balance, SetLpBalance] = useState(0);
    const [pt_balance, SetPtBalance] = useState(0);
    const [yt_balance, SetYtBalance] = useState(0);
    const [ptlp_balance, SetPtLpBalance] = useState(0);
    const [ptLpPool, SetPtLpPool] = useState('');
    const [vaultConfig, setVaultConfig] = useState({});
    const [ptLpPoolState, setPtLpPoolState] = useState({});
    const [lpSupply, setLpSupply] = useState(0);
    const [ptLpPoolAddress, setPtLpPoolAddress] = useState('');
    const [poolAddress, setPoolAddress] = useState('');
    const [poolState, setPoolState] = useState({});
    const [ptLpPoolToken, setPtLpPoolToken] = useState('');
    const [ptLpTokenSupply, setPtLpTokenSupply] = useState('');
    const [flashLoanFee, setFlashLoanFee] = useState(0);
    const [tokenXSymbol, setTokenXSymbol] = useState('');
    const [tokenYSymbol, setTokenYSymbol] = useState('');

    const [updated, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    //fetch terra context objects
    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    let metadataForNetwork;
    if (connectedWallet && connectedWallet.network.chainID.startsWith("pisco")){
        metadataForNetwork = metadata.testnet;
    } else {
        metadataForNetwork = metadata.localterra;
    }

    //zodiac data
    const principalToken = "principal_token" in vaultConfig ? vaultConfig.principal_token : "";
    const lpToken = "collateral_token" in vaultConfig ? vaultConfig.collateral_token : "";
    const ptLpAstroportLink = principalToken > "" ? links.terra_astroport + `swap?from=${principalToken}&to=${lpToken}` : "";

    //wire up to connectedWallet
    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchVaultContext = async () => {
            if (!connectedWallet) {
                console.log('wallet not connected')
                return;
            }

            if (connectedWallet.network.chainID.startsWith("phoenix")) {
                alert(`Please only execute this example on Testnet`);
                return;
            }

            if (!pool || pool === '') {
                return;
            }

            console.log(pool);

            const pool_object = JSON.parse(pool);

            //relevant token balances for selected vault
            terra.wasm.contractQuery(pool_object.collateral_token, { "balance": { "address": connectedWallet.terraAddress } }).then(balance_response => {
                SetLpBalance(balance_response.balance);
            });

            terra.wasm.contractQuery(pool_object.principal_token, { "balance": { "address": connectedWallet.terraAddress } }).then(balance_response => {
                SetPtBalance(balance_response.balance);
            });

            terra.wasm.contractQuery(pool_object.yield_token, { "balance": { "address": connectedWallet.terraAddress } }).then(balance_response => {
                SetYtBalance(balance_response.balance);
            });

            //pt-lp pool for vamm
            terra.wasm.contractQuery(metadataForNetwork.zodiac_xyk_vamm_address, { "pools": { "vault_address": pool_object.vault_address } }).then(pools_response => {
                if ("pools" in pools_response && pools_response.pools[0]) {
                    SetPtLpPool(JSON.stringify(pools_response.pools[0]));
                    setPtLpPoolAddress(pools_response.pools[0].pool_address);
                }
            });

            //vault config
            terra.wasm.contractQuery(pool_object.vault_address, { "config": {} }).then(config_response => {
                setVaultConfig(config_response);
                setPoolAddress(config_response.pool_address);
            });

            terra.wasm.contractQuery(pool_object.collateral_token, { "token_info": {} }).then(info_response => {
                setLpSupply(info_response.total_supply);
            });
        };

        fetchVaultContext()
            .then()
            .catch((err) => console.log('errror', err))
    }, [connectedWallet, pool, terra, updated, metadataForNetwork.zodiac_xyk_vamm_address]);

    //these are downstream contract queries that depend on fetchVaultContext() resolving
    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchVaultDownstreamData = async () => {
            //underlying pool state
            if (ptLpPoolAddress > '') {
                terra.wasm.contractQuery(ptLpPoolAddress, { "pool": {} }).then(pool_response => {
                    setPtLpPoolState(pool_response);
                });
                terra.wasm.contractQuery(ptLpPoolAddress, { "pair": {} }).then(pair_response => {
                    setPtLpPoolToken(pair_response.liquidity_token);
                });
            }
        };

        fetchVaultDownstreamData()
            .then()
            .catch((err) => console.log('errror', err))
    }, [ptLpPoolAddress, updated, terra.wasm]);

    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchPoolState = async () => {

            if (poolAddress > '') {
                terra.wasm.contractQuery(poolAddress, { "pool": {} }).then(pool_response => {
                    setPoolState(pool_response);
                });
            }
        };

        fetchPoolState()
            .then()
            .catch((err) => console.log('errror', err))
    }, [poolAddress, updated, terra.wasm]);

    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchPtLpTokenSupply = async () => {
            if (ptLpPoolToken > '') {
                terra.wasm.contractQuery(ptLpPoolToken, { "token_info": {} }).then(info_response => {
                    setPtLpTokenSupply(info_response.total_supply);
                });

                terra.wasm.contractQuery(ptLpPoolToken, { "balance": { "address": connectedWallet.terraAddress } }).then(balance_response => {
                    SetPtLpBalance(balance_response.balance);
                });
            }
        };

        fetchPtLpTokenSupply()
            .then()
            .catch((err) => console.log('errror', err))
    }, [ptLpPoolToken, updated, terra.wasm, connectedWallet]);

    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchFlashLoanFee = async () => {
            if ("flash_loan_address" in vaultConfig) {
                terra.wasm.contractQuery(vaultConfig.flash_loan_address, { "config": {} }).then(config_response => {
                    setFlashLoanFee(config_response.fee);
                });
            }
        };

        fetchFlashLoanFee()
            .then()
            .catch((err) => console.log('errror', err))
    }, [vaultConfig, updated, terra.wasm]);

    useEffect(() => {

        //dispatch query to fetch extra context given selected vault, store results
        const fetchPoolAssets = async () => {
            if ("assets" in poolState) {

                if ("token" in poolState.assets[0].info){
                    terra.wasm.contractQuery(poolState.assets[0].info.token.contract_addr, {"token_info":{}}).then(response =>{
                        setTokenXSymbol(response.symbol);
                    })
                } else {
                    setTokenXSymbol(poolState.assets[0].info.native_token.denom);
                }

                if ("token" in poolState.assets[1].info){
                    terra.wasm.contractQuery(poolState.assets[1].info.token.contract_addr, {"token_info":{}}).then(response =>{
                        setTokenYSymbol(response.symbol);
                    })
                } else {
                    setTokenYSymbol(poolState.assets[1].info.native_token.denom);
                }
            }
        };

        fetchPoolAssets()
            .then()
            .catch((err) => console.log('errror', err))
    }, [poolState, updated, terra.wasm]);

    const vaultContext = {
        lp: lp_balance,
        pt: pt_balance,
        yt: yt_balance,
        ptLpPool: ptLpPool,
        config: vaultConfig,
        ptLpPoolState: ptLpPoolState,
        poolState: poolState,
        lpSupply: lpSupply,
        ptLpTokenSupply: ptLpTokenSupply,
        flashLoanFee: flashLoanFee,
        ptlp: ptlp_balance,
        ptLpPoolTokenAddress: ptLpPoolToken,
        tokenXSymbol: tokenXSymbol,
        tokenYSymbol: tokenYSymbol,
    };

    return (
        <Grid
            templateAreas={`
        "side main"
        "side footer"`}
            gridTemplateColumns={'270px 1fr'}
            gap='6'
        >
            <SidePanel />
            <GridItem area={'main'}>
                <Header
                    lp={lp_balance}
                    pt={pt_balance}
                    yt={yt_balance}
                    pool={pool}
                    vaultContext={vaultContext}
                    forceUpdate={forceUpdate}
                    updated={updated}
                />
                <Grid
                    templateColumns='repeat(3, 1fr)' gap={6}>
                    <Card title='PT-LP' url={ptLpAstroportLink}>
                        <Stack
                            direction='row'
                            alignItems='center'
                            mb='5'
                            justifyContent='space-evenly'>
                            <TriangleTopLeft text={'P'} />
                            <FaPlus style={{ fontSize: '2rem' }} />
                            <Square text={'LP'} />
                        </Stack>
                        <ZodiacPLPPool
                            pool={pool}
                            vaultContext={vaultContext}
                            forceUpdate={forceUpdate} />
                    </Card>

                    <Card title='Swap'>
                        <Vamm
                            pool={pool}
                            vaultContext={vaultContext}
                            forceUpdate={forceUpdate} />
                    </Card>
                    <Card title='Early redeem'>
                        <Stack 
                            direction='row'
                            alignItems='center'
                            mb='5'
                            justifyContent='space-evenly'>
                            <TriangleTopLeft text={'P'} />
                            <FaPlus style={{ fontSize: '2rem' }} />
                            <TriangleBottomRight text={'Y'} />
                        </Stack>
                        <ZodiacEarlyExit
                            pool={pool}
                            vaultContext={vaultContext}
                            forceUpdate={forceUpdate} />
                    </Card>
                </Grid>
            </GridItem>
        </Grid>
    )
}