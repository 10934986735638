import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import metadata from "../../metadata.json";
import { TransactionFeedback } from '../transaction_feedback'
import { Input, Stack } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'

export default function AstroportCreatePLPPool() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [plpToken, setPLPToken] = useState('');
    const [lpToken, setLpToken] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)            
            const create_pair_msg = new MsgExecuteContract(
                connectedWallet.walletAddress,
                metadata.astroport_factory_address,
                {
                    create_pair: {
                        pair_type: {
                            xyk: {}
                        },
                        asset_infos: [
                            {
                                token: {
                                    contract_addr: plpToken,
                                }
                            },
                            {
                                token: {
                                    contract_addr: lpToken,
                                }
                            },
                        ],
                    }
                }
            );

            const transactionMsg = {
                msgs: [
                    create_pair_msg,
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg)

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText(
                "The pool address is " + 
                result.logs[0].eventsByType["wasm"]["pair_contract_addr"][0] + 
                "  |||||  " +
                "The lp token address is " + 
                result.logs[0].eventsByType["wasm"]["liquidity_token_addr"][0]
            );

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)
    }, [connectedWallet, terra, plpToken, lpToken]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="plp token"
                        type="text"
                        value={plpToken}
                        placeholder="plp token address"
                        onChange={e => setPLPToken(e.target.value)} />
                    <Input
                        name="lp token"
                        type="text"
                        value={lpToken}
                        placeholder="lp token address"
                        onChange={e => setLpToken(e.target.value)} />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                         
                    <DefaultButton onClick={sendTransaction}>Create pt-lp pool</DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet} 
                targetWord={txText} />
        </>
    );
}