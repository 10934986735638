export const metadata = {
  "testnet": {
    "cw20_code_id": 300,
    "astroport_factory_address": "terra1z3y69xas85r7egusa0c7m5sam0yk97gsztqmh8f2cc6rr4s4anysudp7k0",
    "astroport_generator_address": "terra1gc4d4v82vjgkz0ag28lrmlxx3tf6sq69tmaujjpe7jwmnqakkx0qm28j2l",
    "astroport_whitelist_address": "terra17yfjhj4adsqlsm452a4hawp6lgtzd2lyrqnmfe057vd5pcpd8rwstqd85l",
    "zodiac_yield_token_code_id": 5069,
    "zodiac_xyk_vault_code_id": 5070,
    "zodiac_flash_loan_address": "terra1derqrynksuepqj3zvyp9nqepezluwz6m673rvu2cm6tlvfznvfkqdqk7u9",
    "zodiac_fee_collector_address": "terra1vx2ep477nfudrwcutxmdstdzsg7y9alhc0z3qlmgd93k29nfccesnq8p37",
    "zodiac_xyk_vamm_address": "terra19vkc2qxddqz408c40kjelck50m7r53xy8lat7ygzh8pehlkvqhvqqr9v74",
    "zodiac_factory_address": "terra1tvphvthnxe4h0gw0575vn6qhue9g70x4k3qep2dnnzq6jqym65vqk9wyxg",
  },

  "localterra": {
      "cw20_code_id": 27,
      "astroport_factory_address": "terra1sjfach0vqaz5dyqum9e5wk7jesltpdugewzhz94xvs5ayt28dnyq8p0f79",
      "astroport_generator_address": "terra1ch4s3kkpsgvykx5vtz2hsca4gz70yks5v55nqcfaj7mgsxjsqypsjxqava",
      "astroport_whitelist_address": "terra17yfjhj4adsqlsm452a4hawp6lgtzd2lyrqnmfe057vd5pcpd8rwstqd85l",
      "zodiac_yield_token_code_id": 105,
      "zodiac_xyk_vault_code_id": 106,
      "zodiac_flash_loan_address": "terra1q7nhnf84jk923za2e7v3rrseuakmk9cxgk93f4g7tylrvsl4psqs0d7yeq",
      "zodiac_fee_collector_address": "terra1erd7fc67ynkjcrsacuk0qrsxdmcs3jesrkaznczu7ddq8znr34lq9c68lp",
      "zodiac_xyk_vamm_address": "terra1r8g9eknxyas6nh6du6d8w2u0ru3pf6zrylwgums46t0n9u6vx27s8h9mcy",
      "zodiac_factory_address": "terra1ks7mcct7mc5hvr4rpttwd4wyr5eahw635vjnwyfg4gswx7xyk4nswdkztv",
    },
}