import { createContext, useContext, useState } from "react";

const ViewStoreContext = createContext();

const INIT = {
  currentPool: ''
}

export const ViewStoreProvider = props => {
  if (props == null) {
    throw new Error("props undefined")
  }

  const { load, ...rest } = props
  const [state, setState] = useState(INIT);
  console.log(state)
  return <ViewStoreContext.Provider value={[state, setState]} {...rest} />
}

export const useViewStore = () => useContext(ViewStoreContext)
