import { NavLink } from 'react-router-dom'
import { Box, Flex } from "@chakra-ui/react"
import { Logo } from './logo'
import { ConnectWallet } from './connect_wallet'
import {
    useConnectedWallet,
} from "@terra-money/wallet-provider";

export function Navbar() {

    const connectedWallet = useConnectedWallet();

    return (
        <Flex
            align="center"
            justify="space-between"
            w="100%"
            mb={8}>
            <Flex gap={8}>
                <Box w="2rem">
                    <NavLink to="/app"><Logo /></NavLink>
                </Box>
                <NavLink to="/app">App</NavLink>
                {
                    connectedWallet && !connectedWallet.network.chainID.startsWith("pisco") && !connectedWallet.network.chainID.startsWith("phoenix") && 
                    <>
                        <NavLink to="/createvault">Create Vault</NavLink>
                        <NavLink to="/governance">Governance</NavLink>
                        <NavLink to="/analytics">Analytics</NavLink>
                    </>
                }
            </Flex>
            <>
                <ConnectWallet />
            </>
        </Flex>
    )
}
