import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import { DefaultButton } from './button'
import { HStack, Text } from '@chakra-ui/react'

export const ConnectWallet = () => {
/*   const {
    client: keplr,
    account: keplrAccount,
    connect: connectKeplr,
    disconnect: disconnectKeplr
  } = useKeplr() */

  const {
    status,
    wallets,
    connect,
    disconnect,
  } = useWallet()

  return (
    <div>
      {status === WalletStatus.WALLET_NOT_CONNECTED && (
        <HStack>
          <DefaultButton
            onClick={() => connect('EXTENSION')}
          >
            Connect Terra
          </DefaultButton>

          <DefaultButton isDisabled={true}>
            Connect Osmosis
          </DefaultButton>
        </HStack>
      )}
      {status === WalletStatus.WALLET_CONNECTED && (
        <HStack>
          <div className="connected-address">
            <Text>
              {wallets[0].terraAddress.slice(0, 9)}...{wallets[0].terraAddress.slice(-4)}
            </Text>
          </div>
          <DefaultButton onClick={() => disconnect()}>
            X
          </DefaultButton>
        </HStack>
      )}
{/*       {keplr && (
        <HStack>
          <div className="connected-address">
            <DefaultButton className="btn-clear">
              {keplrAccount.slice(0, 9)}...{keplrAccount.slice(-4)}
            </DefaultButton>
          </div>
          <DefaultButton className="connected-disconnect" onClick={() => disconnectKeplr()} type="button">
            X
          </DefaultButton>
        </HStack>
      )
      } */}
    </div>
  )
}
