import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from '../transaction_feedback'
import { Input, Stack } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'

export default function AstroportAutoTrades() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [pairAddress, setPairAddress] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const fabricateSwapToLuna = (walletAddress, pairAddress, tokenX, amount) => {

        const msg = {
            "swap":{
                "max_spread": "0.5"
            }
        };

        const buff = new Buffer.from(
            JSON.stringify(msg)
        );
        const binary_msg = buff.toString("base64");

        return new MsgExecuteContract(
            walletAddress,
            tokenX,
            {
                "send": {
                    "contract": pairAddress,
                    "amount": amount,
                    "msg": binary_msg,
                }
            },
            { uluna: amount }
        );
    };

    const fabricateSwapFromLuna = (walletAddress, pairAddress, amount) => {
        return new MsgExecuteContract(
            walletAddress,
            pairAddress,
            {
                "swap": {
                    "offer_asset": {
                        "info": {"native_token": {"denom": "uluna"}},
                        "amount": amount,
                    },
                    "max_spread": "0.5",
                }
            },
            {"uluna": amount}
        );
    };

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)

            const poolInfo = await terra.wasm.contractQuery(
                pairAddress,
                {
                    "pool":{}
                }
            );

            let tokenAddress;
            if (!poolInfo.assets[0].info.hasOwnProperty("native_token")){
                tokenAddress = poolInfo.assets[0].info.token.contract_addr;
            } else {
                tokenAddress = poolInfo.assets[1].info.token.contract_addr;
            }

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    fabricateSwapFromLuna(connectedWallet.walletAddress, pairAddress, "10000000"),
                    fabricateSwapToLuna(connectedWallet.walletAddress, pairAddress, tokenAddress, "9300000"),

                    fabricateSwapFromLuna(connectedWallet.walletAddress, pairAddress, "10000000"),
                    fabricateSwapToLuna(connectedWallet.walletAddress, pairAddress, tokenAddress, "9200000"),
                    
                    fabricateSwapFromLuna(connectedWallet.walletAddress, pairAddress, "10000000"),
                    fabricateSwapToLuna(connectedWallet.walletAddress, pairAddress, tokenAddress, "9100000"),
                    
                    fabricateSwapFromLuna(connectedWallet.walletAddress, pairAddress, "10000000"),
                    fabricateSwapToLuna(connectedWallet.walletAddress, pairAddress, tokenAddress, "9000000"),
                    
                    fabricateSwapFromLuna(connectedWallet.walletAddress, pairAddress, "10000000"),
                    fabricateSwapToLuna(connectedWallet.walletAddress, pairAddress, tokenAddress, "9000000"),                    
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)        
    }, [connectedWallet, terra, pairAddress]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="pair address"
                        type="text"
                        value={pairAddress}
                        placeholder="xxx-luna astroport pool"
                        onChange={e => setPairAddress(e.target.value)} />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                        
                    <DefaultButton onClick={sendTransaction}>Generate trades</DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet} />
        </>
    );
}