import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import metadata from "../../metadata.json";
import { TransactionFeedback } from '../transaction_feedback'
import { Input, Stack } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'

export default function ZodiacRegisterPLPPool() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [vaultAddress, setVaultAddress] = useState('');
    const [ptLpPoolAddress, setPtLpPoolAddress] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)

            const register_pt_lp_msg = new MsgExecuteContract(
                connectedWallet.walletAddress,
                metadata.zodiac_xyk_vamm_address,
                {
                    "upsert_vault_pool_mapping":{
                        "vault_address": vaultAddress,
                        "pool_address": ptLpPoolAddress,
                        "pool_fee": 30,
                    }
                }
            );

            const transactionMsg = {
                msgs: [
                    register_pt_lp_msg,
                ],
            };

            //terra1hqvpkqg5rzgry5k0y5n5fy4xwpaetky7hxc8ypf34sp5plnw6f6qmjt8yy

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText(
                ""
            );

        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }

        setIsProcessing(false)
    }, [connectedWallet, terra, vaultAddress, ptLpPoolAddress]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="vault address"
                        type="text"
                        value={vaultAddress}
                        placeholder="vault address"
                        onChange={e => setVaultAddress(e.target.value)} />
                    <Input
                        name="pt-lp pool address"
                        type="text"
                        value={ptLpPoolAddress}
                        placeholder="pt-lp pool address"
                        onChange={e => setPtLpPoolAddress(e.target.value)} />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                        
                    <DefaultButton onClick={sendTransaction}>Register pt-lp pool to vamm</DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet} 
                targetWord={txText} />
        </>
    );
}