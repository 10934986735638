import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import metadata from "../../metadata.json";
import { Container, Input, InputGroup, Link, Stack, Text } from '@chakra-ui/react'
import { isNumber } from '../../util/validation'
import { DefaultSpinner } from '../spinner'

export default function ZodiacCreateVault() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [poolAddress, setPoolAddress] = useState('');
    const [vaultName, setVaultName] = useState('');
    const [maturityYear, setMaturityOffset] = useState('3600')
    const [pTokenLiquidity, setPTokenLiquidity] = useState('1');
    const [redeemFee, setRedeemFee] = useState('100');
    const [claimYieldFee, setClaimYieldFee] = useState('1000');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }

        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        const result = await terra.wasm.contractQuery(poolAddress, {"pair":{}})
        const lpTokenAddress = result.liquidity_token;

        try {
            setIsProcessing(true)            
            const buff = new Buffer.from(
                JSON.stringify({
                    "pool_address": poolAddress,
                    "incentive_denoms": [],
                    "ptoken_l": pTokenLiquidity,
                    "flash_loan_address": metadata.zodiac_flash_loan_address,
                    "fee_collector": metadata.zodiac_fee_collector_address,
                    "redeem_fee": Number(redeemFee),
                    "claim_yield_fee": Number(claimYieldFee),
                    "generator_address": metadata.astroport_generator_address,
                    "incentives_expiration_timestamp": 0,
                    "keeper_scripts": [],
                    "owner": connectedWallet.walletAddress,
                  })
            );
            const binary_msg = buff.toString("base64");
    
            const message = {
                "create_vault": {
                  "vault_type": "xyk",
                  "collateral_token": lpTokenAddress,
                  "maturity_month": 1,
                  "maturity_year": Number(maturityYear),
                  "options": binary_msg,
                  "name": vaultName
                }
            };

            const create_vault_msg = new MsgExecuteContract(connectedWallet.walletAddress, metadata.zodiac_factory_address, message);

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    create_vault_msg
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)
    }, [connectedWallet, terra, poolAddress, maturityYear, redeemFee, claimYieldFee, vaultName, pTokenLiquidity]);

    const txStringifiedResult = txResult ? JSON.stringify(txResult, null, 2) : ''
    const txhash = txStringifiedResult.slice(txStringifiedResult.indexOf('txhash')).split('"')[2]

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Text>Contract addresses</Text>
                    <Input
                        name="poolAddress"
                        type="text"
                        value={poolAddress}
                        placeholder="pool address"
                        onChange={e => setPoolAddress(e.target.value)} />
                    <Text>Fees</Text>
                    <InputGroup>
                        <Input
                            name="redeem fee"
                            type="text"
                            value={redeemFee}
                            placeholder={redeemFee}
                            onChange={e => isNumber(e.target.value) && setRedeemFee(e.target.value)} />
                        <Input
                            name="claim yield fee"
                            type="text"
                            value={claimYieldFee}
                            placeholder={claimYieldFee}
                            onChange={e => isNumber(e.target.value) && setClaimYieldFee(e.target.value)} />
                    </InputGroup>
                    <Text>Vault info</Text>
                    <Input
                        name="vault name"
                        type="text"
                        value={vaultName}
                        placeholder="vault name"
                        onChange={e => setVaultName(e.target.value)} />
                    <Input
                        name="maturity year"
                        type="text"
                        value={maturityYear}
                        placeholder="maturity year for zodiac factory contract"
                        onChange={e => setMaturityOffset(e.target.value)} />
                    <Input
                        name="u-pLP token value in liquidity units"
                        type="text"
                        value={pTokenLiquidity}
                        placeholder={pTokenLiquidity}
                        onChange={e => setPTokenLiquidity(e.target.value)} />
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }
                    <DefaultButton onClick={sendTransaction}>Create vault</DefaultButton>
                </Stack>
            )}

            {
                txResult &&
                <Container>
                    <Text>Vault created!</Text>
                    {
                        connectedWallet?.network.chainID !== 'localterra' &&
                        <Link href={`https://finder.terra.money/mainnet/tx/${txhash}`} isExternal>
                            link to transaction
                        </Link>
                    }
                </Container>
            }
            {
                txError &&
                <Container>
                    <Text>Vault creation failed.</Text>
                    <Text color='red'>{txError}</Text>
                </Container>
            }
        </>
    );
}
