import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from "../transaction_feedback";
import { Flex, Input, Stack, Spacer, Text } from '@chakra-ui/react'
import { isNumber } from '../../util/validation'
import { units } from "../../constants/units"
import { colors } from '../../constants/style'
import { DefaultSpinner } from '../spinner'
import { calc_liquidity_per_lp } from "../../util/calcs";

export default function ZodiacEarlyExit({ pool, vaultContext, forceUpdate }) {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [amount, setAmount] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    //ui placeholders
    const vaultAddress = pool && pool !== '' ? JSON.parse(pool).vault_address : '';
    const amountPlaceholder = Math.min(vaultContext["pt"], vaultContext["yt"]);

    const liquidityPerPt = "ptoken_l" in vaultContext.config ? Number(vaultContext.config.ptoken_l) : 0;
    const liquidityPerLP = calc_liquidity_per_lp(vaultContext.lpSupply, vaultContext.poolState);
    const redeem_fee = "redeem_fee" in vaultContext.config ? vaultContext.config.redeem_fee / 10000.0 : 0;

    const fabricateCombineMessage = (walletAddress, vaultAddress, amount, ) => {
        return new MsgExecuteContract(
            walletAddress,
            vaultAddress,
            {
                "combine": {
                    "amount": String(Math.floor(amount * units.MILLION))
                }
            }
        );
    };

    const fabricateAllowanceMessage = (walletAddress, vaultAddress, token, amount) => {
        return new MsgExecuteContract(
            walletAddress,
            token,
            {
                "increase_allowance": {
                    "spender": vaultAddress,
                    "amount": String(Math.floor(amount * units.MILLION)),
                }
            }
        );
    };

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)
            setTxResult(null)
            setTxError(null)
            //set token addresses
            const plpToken = JSON.parse(pool).principal_token;
            const ylpToken = JSON.parse(pool).yield_token;

            const amount_in = amount === "" ? amountPlaceholder / units.MILLION : amount; //in millions

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    fabricateAllowanceMessage(connectedWallet.walletAddress, vaultAddress, plpToken, amount_in),
                    fabricateAllowanceMessage(connectedWallet.walletAddress, vaultAddress, ylpToken, amount_in),
                    fabricateCombineMessage(connectedWallet.walletAddress, vaultAddress, amount_in),
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText(
                "LP received: " +
                String(Number(result.logs[result.logs.length - 1].eventsByType["wasm-zodiac_redeem"]["lp_redeemed"][0]) / units.MILLION)
            );

            forceUpdate();
        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)
    }, [connectedWallet, terra, amount, pool, vaultAddress, forceUpdate, amountPlaceholder]);

    const prevPoolRef = useRef();
    useEffect(() => {
        if (prevPoolRef.current !== pool) {
            setTxResult('')
            setTxError('')
        }
        prevPoolRef.current = pool;
    }, [pool]);

    return (
        <>
            {connectedWallet?.availablePost && (
                <Stack spacing={3} mb={10}>
                    <Input
                        name="amount"
                        type="text"
                        value={amount}
                        placeholder={amountPlaceholder / units.MILLION}
                        onChange={e => isNumber(e.target.value) && setAmount(e.target.value)}
                        isDisabled={!pool || amountPlaceholder <= 0} />
                    <Text
                        fontSize='xs'
                        textAlign='right'
                        color={colors.FADE_WHITE}>
                        {`expected LP: ${pool ?
                            amount > 0 ?
                                (amount * (1 - redeem_fee) * liquidityPerPt / liquidityPerLP).toFixed(4)
                                :
                                (amountPlaceholder / units.MILLION * (1 - redeem_fee) * liquidityPerPt / liquidityPerLP).toFixed(4)
                            : '-'}`}
                    </Text>
                    <Flex fontSize='sm'>
                        <Text>Redemption fee</Text>
                        <Spacer />
                        <Text>{redeem_fee > 0 ? (redeem_fee * 100).toFixed(2) + '%' : "-%"}</Text>
                    </Flex>
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }
                    <DefaultButton
                        borderRadius='18px'
                        isDisabled={!pool || amountPlaceholder <= 0}
                        onClick={sendTransaction}>
                        Early exit
                    </DefaultButton>
                </Stack>
            )}
            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet}
                targetWord={txText} />
        </>

    );
}