export function Logo({ width, height }) {
  return <svg className="logo" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
    <path d="M 85 15
             L 15 85"
      style={{ strokeOpacity: 0.8 }} />
    <path d="M 15 15
             L 85 15" />
    <path d="M 15 85
             L 85 85" />
  </svg>
}
