import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import { isNumber } from '../../util/validation'
import { useKeplr } from "../useKeplr";
import { cosmos } from "osmojs";

export default function Tx() {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txAmount, setTxAmount] = useState('');
    const [reciever, setReciever] = useState('');

    const { client, account } = useKeplr()

    const sendTransaction = useCallback(async () => {
        if (!client) {
            return;
        }
        // if (connectedWallet.network.chainID.startsWith("osmosis")) {
        //     alert(`Please only execute this example on Testnet`);
        //     return;
        // }

        try {
            const fee = new cosmos.tx.v1beta1.Fee(1000000, "150000uluna")
            const transactionMsgs = [
                new cosmos.bank.v1beta1.MsgSend(account, reciever, {
                    uluna: txAmount * 1000000,
                }),
            ]

            const tx = await client.signAndBroadcast(account, transactionMsgs, fee);
            setTxResult(tx);

        } catch (error) {
            setTxError(
                "Unknown Error: " +
                (error instanceof Error ? error.message : String(error))
            )
        }
    }, [client, account, txAmount, reciever]);

    const handleTxAmount = (value) => {
        if (isNumber(value)) {
            setTxAmount(value)
        }
    }

    return (
        <>
            {client && !txResult && !txError && (
                <>
                    <input
                        name="transaction amont"
                        type="text"
                        value={txAmount}
                        placeholder="amount"
                        onChange={e => handleTxAmount(e.target.value)} />
                    <input
                        name="reciever wallet"
                        type="text"
                        value={reciever}
                        placeholder="wallet address"
                        onChange={e => setReciever(e.target.value)}
                    />
                    <DefaultButton onClick={sendTransaction}>Send {txAmount} to {reciever}</DefaultButton>
                </>
            )}

            {txResult && <>{JSON.stringify(txResult, null, 2)}</>}
            {txError && <pre>{txError}</pre>}
        </>
    );
}