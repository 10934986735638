import { GridItem, Input, Stack, useRadioGroup } from "@chakra-ui/react"
import { useState, useCallback, useMemo } from "react";
import { SEARCH, useVaults } from "../hooks/vaults";
import { colors } from "../constants/style"
import { RadioCard } from './radio_card'
import { useViewStore } from "../hooks/view_state";

export function SidePanel() {
    useVaults()
    const [, setState] = useViewStore()

    // UI hooksf
    const [search, setSearch] = useState('')
    const handleSearchChange = useCallback((e) => setSearch(e.target.value), [setSearch])


    const items = useMemo(() => {
        if (search) {
            const result = SEARCH.search(search)
            return result.map(entry => entry.item)
        }
    }, [search])

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'vaults',
        onChange: (pool) => setState((state) => ({ ...state, currentPool: pool })),
    })
    const group = getRootProps()

    return (
        <GridItem area={'side'} borderRadius='20px' backgroundColor={colors.GRAY} p='3'>
            <Input placeholder='Search (ex: "LUNA 2023")' value={search} onChange={handleSearchChange} />
            <Stack {...group} mt='5'>
                {items && items.map((vault) => {
                    const radio = getRadioProps({ value: JSON.stringify(vault) })

                    return (
                        <RadioCard key={vault.vault_address} {...radio} >
                            {vault.display_name + " " + vault.maturity_year}
                        </RadioCard>)
                })}
            </Stack>
        </GridItem>
    )
}