import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from '../transaction_feedback'
import { Stack, Text } from '@chakra-ui/react'
import { DefaultSpinner } from '../spinner'
import { units } from "../../constants/units";

export default function YLPClaimYield( { pool, vaultContext, forceUpdate, claimableYield }) {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    const vaultAddressPlaceholder = pool && pool !== '' ? JSON.parse(pool).vault_address : '';

    const yieldFee = "claim_yield_fee" in vaultContext.config ? vaultContext.config.claim_yield_fee / 10000.0 : 0;
    const yieldFeeMultiplier = (1 - yieldFee);

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)
            const claim_msg = new MsgExecuteContract(
                connectedWallet.walletAddress,
                vaultAddressPlaceholder,
                {
                    "claim_yield": {
                    },
                },
            );

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    claim_msg
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);
            setTxText(
                "LP earned: " + 
                Number(result.logs[0].eventsByType["wasm"]["rewards"][0]) / units.MILLION
            );

            forceUpdate();
        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)        
    }, [connectedWallet, terra, vaultAddressPlaceholder, forceUpdate]);

    return (
        <>
            {connectedWallet?.availablePost && !txResult && !txError && (
                <Stack spacing={3} mb={10}>
                    <Stack direction='row'>
                        <Text>
                            {`Claimable LP: `}
                        </Text>
                        <Text fontWeight='bold'>
                        {claimableYield > 0 ? (claimableYield * yieldFeeMultiplier / units.MILLION).toFixed(2) : '-'}
                        </Text>
                    </Stack>
                    {
                        isProcessing &&
                        <DefaultSpinner />
                    }                
                    <DefaultButton onClick={sendTransaction}>Proceed</DefaultButton>
                </Stack>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet}
                targetWord={txText} />
        </>
    );
}