import React, { useContext, useState } from "react"
import { keplrSuggestChain } from "./keplr"

export const KeplrContext = React.createContext()

const DEFAULT_STATE = {
  client: null,
  account: '',
}

export function KeplrProvider({ children }) {
  const [state, setState] = useState(DEFAULT_STATE)

  const connect = async () => {
    const client = await keplrSuggestChain(
      // TODO: get env vars working
      'localosmosis', // process.env.CHAIN_ID || 'localosmosis',
      'Local Osmosis CICD', // process.env.CHAIN_ID || 'localosmosis',
      'http://54.92.222.57:26657', // process.env.CHAIN_RPC || 'http://localhost:26657',
      'https://54.92.222.57:1317', // process.env.CHAIN_LCD || 'http://localhost:1317',
    )
    window.client = client

    setState({
      client,
      account: (await client.signer.getAccounts())[0].address
    })
  }

  const disconnect = () => {
    setState(DEFAULT_STATE)
    window.client = null
  }

  return (
    <KeplrContext.Provider
      value={{
        client: state.client,
        account: state.account,
        connect,
        disconnect
      }}
    >
      {children}
    </KeplrContext.Provider>
  )
}

export function useKeplr() {
  const { client, account, connect, disconnect } = useContext(KeplrContext)

  return { client, account, connect, disconnect }
}
