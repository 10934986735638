import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider'

import './theme.css'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { HashRouter } from 'react-router-dom'
import theme from './theme'
import { ViewStoreProvider } from './hooks/view_state'

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
          <WalletProvider {...chainOptions}>
            <ViewStoreProvider>
              <HashRouter >
                <App />
              </HashRouter>
            </ViewStoreProvider>
          </WalletProvider>
      </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
