import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from '../transaction_feedback'
import { metadata } from "../../constants/metadata"
import { Input, Stack, Flex, Spacer, Text } from '@chakra-ui/react'
import { isNumber } from '../../util/validation'
import { units } from "../../constants/units";
import { DefaultSpinner } from '../spinner'
import { ClickableArrowLeft, ClickableArrowRight, TriangleTopLeft, TriangleBottomRight } from '../shapes'
import { colors } from '../../constants/style'
import { calc_liquidity_per_lp, calc_days_since_created } from "../../util/calcs";

export default function Vamm({ pool, vaultContext, forceUpdate }) {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [txText, setTxText] = useState('');
    const [amount, setAmount] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [swapToYt, setSwapToYt] = useState(true);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    let metadataForNetwork;
    if (connectedWallet && connectedWallet.network.chainID.startsWith("pisco")){
        metadataForNetwork = metadata.testnet;
    } else {
        metadataForNetwork = metadata.localterra;
    }

    /////////////////////////////
    //data from contract queries
    /////////////////////////////

    const pool_object = pool && pool !== '' ? JSON.parse(pool) : '';
    const balance = swapToYt ? vaultContext.pt : vaultContext.yt;

    //pt-lp pool metrics
    const ptIsX = "assets" in vaultContext.ptLpPoolState && vaultContext.ptLpPoolState.assets[0].info.token.contract_addr === JSON.parse(pool).principal_token ? true : false;

    const ptPrice = "assets" in vaultContext.ptLpPoolState ?
        ptIsX ?
            Number(vaultContext.ptLpPoolState.assets[1]["amount"]) / Number(vaultContext.ptLpPoolState.assets[0]["amount"])
            :
            Number(vaultContext.ptLpPoolState.assets[0]["amount"]) / Number(vaultContext.ptLpPoolState.assets[1]["amount"])
        :
        0;

    const ptQuantityInPtLpPool = vaultContext.ptLpPool > '' && "assets" in vaultContext.ptLpPoolState ?
        ptIsX ?
            Number(vaultContext.ptLpPoolState.assets[0]["amount"])
            :
            Number(vaultContext.ptLpPoolState.assets[1]["amount"])
        :
        0;

    const lpQuantityInPtLpPool = vaultContext.ptLpPool > '' && "assets" in vaultContext.ptLpPoolState ?
        ptIsX ?
            Number(vaultContext.ptLpPoolState.assets[1]["amount"])
            :
            Number(vaultContext.ptLpPoolState.assets[0]["amount"])
        :
        0;

    //per utoken liquidity
    const liquidityPerPt = "ptoken_l" in vaultContext.config ? Number(vaultContext.config.ptoken_l) : 0;
    const liquidityPerLP = calc_liquidity_per_lp(vaultContext.lpSupply, vaultContext.poolState);

    //fees
    const poolFee = vaultContext.ptLpPool > '' && "pool_fee" in JSON.parse(vaultContext.ptLpPool) ? JSON.parse(vaultContext.ptLpPool).pool_fee / 10000.0 : 0;
    const poolFeeMultiplier = (1 - poolFee);
    const redeem_fee = "redeem_fee" in vaultContext.config ? vaultContext.config.redeem_fee / 10000.0 : 0;
    const redeemFeeMultiplier = (1 - redeem_fee);
    const flashLoanFeeMultiplier = vaultContext ? vaultContext.flashLoanFee / 10000.0 + 1 : 1.0009;

    //time-based metrics
    const liquidityPerLPAtVaultCreation = "pool_state_at_creation" in vaultContext.config ? Math.sqrt(Number(vaultContext.config.pool_state_at_creation[0]) * Number(vaultContext.config.pool_state_at_creation[1])) / Number(vaultContext.config.collateral_token_supply_at_creation) : 1.0;
    const daysSinceVaultCreate = vaultContext.config > '' && "creation_timestamp" in vaultContext.config ?
        calc_days_since_created(vaultContext.config.creation_timestamp): 1;

    const expectedYtFromSwap = (coin_in_amount, A, B, K, L, d, F, C, to_yt) => {

        if (to_yt) {
            const A_1 = A + coin_in_amount * units.MILLION;
            const alpha = d * (B - (A * B) / A_1);
            const B_1 = B - alpha;

            const a = -1 * K * F / (d * L);
            const b = K * B_1 / L - (F * A_1 / d) - (K * alpha * F / (L * d));
            const c = K * B_1 * alpha / L;

            const beta = (-b - Math.sqrt(b * b - (4 * a * c))) / (2 * a);

            const y_out = (alpha + beta) * K / L

            return (y_out / units.MILLION)
        } else {
            const loan = (A * B) / (A - (coin_in_amount * units.MILLION / d)) - B;

            const B_1 = B + loan;
            const A_1 = A - coin_in_amount * units.MILLION;

            const lp_combined = C * coin_in_amount * units.MILLION * L / K;
            const lp_for_swap_out = lp_combined - (loan * F);

            const p_out = d * (A_1 - (A_1 * B_1) / (B_1 + lp_for_swap_out));



            return (p_out > 0 ? p_out / units.MILLION : 0);
        }
    };

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)
            setTxResult(null)
            setTxError(null)

            let hookMsg;
            let tokenAddress;

            //flip swap direction based on properties input from ylp/plp pages
            if (swapToYt) {
                hookMsg = {
                    "to_y_l_p": {
                        "vault_address": pool_object.vault_address,
                        "xyk_pool_address": JSON.parse(vaultContext.ptLpPool).pool_address,
                        "xyk_pool_fee": JSON.parse(vaultContext.ptLpPool).pool_fee,
                    }
                };
                tokenAddress = pool_object.principal_token;
            } else {
                hookMsg = {
                    "to_p_l_p": {
                        "vault_address": pool_object.vault_address,
                        "xyk_pool_address": JSON.parse(vaultContext.ptLpPool).pool_address,
                        "xyk_pool_fee": JSON.parse(vaultContext.ptLpPool).pool_fee,
                    }
                };
                tokenAddress = pool_object.yield_token;
            }

            const buff = new Buffer.from(
                JSON.stringify(hookMsg)
            );
            const binary_msg = buff.toString("base64");

            const amount_in = amount === "" ? balance : Math.floor(amount * units.MILLION); //in micros

            const swap_msg = new MsgExecuteContract(
                connectedWallet.walletAddress,
                tokenAddress,
                {
                    "send": {
                        "contract": metadataForNetwork.zodiac_xyk_vamm_address,
                        "amount": String(amount_in),
                        "msg": binary_msg,
                    },
                },
            );

            //sign & broadcast walletprovider-linekd wallet LP txs
            const transactionMsg = {
                msgs: [
                    swap_msg
                ],
            };

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);

            if (swapToYt) {
                setTxText(
                    "YT received: " +
                    String(Number(result.logs[0].eventsByType['wasm-zodiac_deposit']["ylp_minted"][0]) / units.MILLION)
                );
            } else {
                setTxText(
                    "PT received: " +
                    String(Number(result.logs[0].eventsByType["wasm"]["return_amount"][1]) / units.MILLION)
                );
            }

            forceUpdate();
        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }
        setIsProcessing(false)        
    }, [connectedWallet, terra, swapToYt, amount, pool_object, vaultContext, forceUpdate, balance, metadataForNetwork.zodiac_xyk_vamm_address]);

    const prevPoolRef = useRef();
    useEffect(() => {
        if (prevPoolRef.current !== pool) {
            setTxResult('')
            setTxError('')
        }
        prevPoolRef.current = pool;
    }, [pool]);

    const yield_token_apy = pool && daysSinceVaultCreate < units.VAULT_AGE_APY_THRESHOLD ? 'calculation pending ...' :
        liquidityPerLP > 0 ? (100 * (liquidityPerLP / liquidityPerLPAtVaultCreation - 1) * (365.25 / daysSinceVaultCreate)).toFixed(2) + "%" : "-%"

    return (
        <>
            {connectedWallet?.availablePost && (
                <>
                    <Stack
                        direction='row'
                        alignItems='center'
                        mb='5'
                        justifyContent='space-evenly'>
                        <TriangleTopLeft text='p' />
                        {
                            swapToYt ?
                                <ClickableArrowRight
                                    onClick={e => setSwapToYt(false)} />
                                :
                                <ClickableArrowLeft
                                    onClick={e => setSwapToYt(true)} />
                        }
                        <TriangleBottomRight text='y' />
                    </Stack>
                    <Stack spacing={3} mb={10}>
                        <Input
                            name="amount"
                            type="text"
                            value={amount}
                            placeholder={balance / units.MILLION}
                            onChange={e => isNumber(e.target.value) && setAmount(e.target.value)} 
                            isDisabled={!pool || balance <= 0}/>
                        <Text
                            fontSize='xs'
                            textAlign='right'
                            color={colors.FADE_WHITE}>
                            {`expected ${swapToYt ? "YT" : "PT"}: ${ 
                                pool ? 
                                    amount > 0 ? 
                                        expectedYtFromSwap(amount, ptQuantityInPtLpPool, lpQuantityInPtLpPool, liquidityPerLP, liquidityPerPt, poolFeeMultiplier, flashLoanFeeMultiplier, redeemFeeMultiplier, swapToYt).toFixed(4)
                                        :
                                        expectedYtFromSwap(balance / units.MILLION, ptQuantityInPtLpPool, lpQuantityInPtLpPool, liquidityPerLP, liquidityPerPt, poolFeeMultiplier, flashLoanFeeMultiplier, redeemFeeMultiplier, swapToYt).toFixed(4)
                                : '-'}`}
                        </Text>
                        <Flex fontSize='sm'>
                        <Text>PT yield to maturity</Text>
                            <Spacer />
                            <Text>{liquidityPerPt > 0 ? (100*(liquidityPerPt / (ptPrice * liquidityPerLP) - 1)).toFixed(2) +"%" : '-%'}</Text>
                        </Flex>
                        <Flex fontSize='sm'>
                            <Text>Yield token APY</Text>
                            <Spacer />
                            <Text>{yield_token_apy}</Text>
                        </Flex>
                        {
                            isProcessing &&
                            <DefaultSpinner />
                        }
                        <DefaultButton
                            borderRadius='18px'
                            onClick={sendTransaction}
                            isDisabled={!pool || balance <= 0}>
                            Swap
                        </DefaultButton>
                    </Stack>
                </>
            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet}
                targetWord={txText} />
        </>
    );
}