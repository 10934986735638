import { useConnectedWallet, useLCDClient } from '@terra-money/wallet-provider';
import { useState, useEffect } from 'react';
import { metadata } from "../constants/metadata";
import Fuse from 'fuse.js'

export const VAULTS = []

export const SEARCH = new Fuse(VAULTS, {
    includeScore: true,
    keys: ['display_name', 'maturity_year']
})

export function useVaults() {
    const [vaults, setVaults] = useState([])

    const connectedWallet = useConnectedWallet()
    const terra = useLCDClient()

    let metadataForNetwork;
    if (connectedWallet && connectedWallet.network.chainID.startsWith("pisco")){
        metadataForNetwork = metadata.testnet;
    } else {
        metadataForNetwork = metadata.localterra;
    }

    // wire up to connectedWallet
    useEffect(() => {
        // dispatch query, store results
        const fetchVaults = async () => {
            if (!connectedWallet) {
                console.log('wallet not connected')
                return
            }

            if (connectedWallet.network.chainID.startsWith("phoenix")) {
                alert(`Please only execute this example on Testnet`)
                return;
            }

            if (VAULTS.length === 0) {
                let vault_response = await terra.wasm.contractQuery(
                    metadataForNetwork.zodiac_factory_address,
                    { "vaults": {"limit": 30} }
                )
                VAULTS.push(...vault_response.vaults)
                SEARCH.setCollection(VAULTS)
            }
            setVaults(VAULTS)
        };

        fetchVaults()
            .catch((err) => console.log('errror', err))
    }, [connectedWallet, terra, metadataForNetwork.zodiac_factory_address])

    return {
        vaults
    }
}