import React from 'react';
import { Button } from '@chakra-ui/react'
import { colors } from '../constants/style'

export function DefaultButton({ children, type, className, onClick, isDisabled, borderRadius }) {
  return (
    <Button
      borderRadius={borderRadius}
      color={colors.WHITE}
      onClick={onClick}
      p='0.5rem'
      bg={colors.PURPLE}
      isDisabled={isDisabled}
      _hover={{ bg: colors.FADE_PURPLE }}>
      {children}
    </Button>
  )
}
