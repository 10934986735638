import {
    useConnectedWallet,
    useLCDClient,
    UserDenied,
} from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { DefaultButton } from '../button'
import { TransactionFeedback } from "../transaction_feedback";
import { Input, Stack, Text, Flex, Spacer, Tabs, TabList, TabPanels, TabPanel, Tab, HStack } from '@chakra-ui/react'
import { isNumber } from '../../util/validation'
import { units } from "../../constants/units";
import { DefaultSpinner } from '../spinner'
import { colors } from '../../constants/style'
import { calc_liquidity_per_lp, calc_days_since_created } from "../../util/calcs";

export default function ZodiacPLPPool({ pool, vaultContext, forceUpdate }) {
    const [txResult, setTxResult] = useState(null);
    const [txError, setTxError] = useState(null);
    const [plpTokenAmount, setPLPTokenAmount] = useState('');
    const [lpTokenAmount, setLPTokenAmount] = useState('');
    const [withdrawLpAmount, setWithdrawLpAmount] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const connectedWallet = useConnectedWallet();
    const terra = useLCDClient();

    //extract data from props
    const poolAddress = vaultContext.ptLpPool > '' && JSON.parse(vaultContext.ptLpPool).pool_address ? JSON.parse(vaultContext.ptLpPool).pool_address : '';
    const lpBalance = vaultContext.lp;
    const ptBalance = vaultContext.pt;
    const ptLpBalance = vaultContext.ptlp;

    //per utoken liquidity metrics
    const liquidityPerPt = "ptoken_l" in vaultContext.config ? Number(vaultContext.config.ptoken_l) : 0;
    const liquidityPerLP = calc_liquidity_per_lp(vaultContext.lpSupply, vaultContext.poolState);;
 
    //pt-lp pool metrics
    const ptPairIndex = "assets" in vaultContext.ptLpPoolState && vaultContext.ptLpPoolState.assets[0].info.token.contract_addr === JSON.parse(pool).principal_token ? 0 : 1;
    const lpPairIndex = 1 - ptPairIndex;

    const ptQuantity = "assets" in vaultContext.ptLpPoolState ? Number(vaultContext.ptLpPoolState.assets[ptPairIndex]["amount"]) : 0;
    const lpQuantity = "assets" in vaultContext.ptLpPoolState ? Number(vaultContext.ptLpPoolState.assets[lpPairIndex]["amount"]) : 0;

    const ptPrice = "assets" in vaultContext.ptLpPoolState ?
        Number(vaultContext.ptLpPoolState.assets[lpPairIndex]["amount"]) / Number(vaultContext.ptLpPoolState.assets[ptPairIndex]["amount"])
        :
        0;
    const lpPrice = "assets" in vaultContext.ptLpPoolState ?
        Number(vaultContext.ptLpPoolState.assets[ptPairIndex]["amount"]) / Number(vaultContext.ptLpPoolState.assets[lpPairIndex]["amount"])
        :
        0;

    const ptQuantityPerVammLp = Math.floor(units.MILLION * ptQuantity / vaultContext.ptLpTokenSupply) / units.MILLION;
    const lpQuantityPerVammLp = Math.floor(units.MILLION * lpQuantity / vaultContext.ptLpTokenSupply) / units.MILLION;
    const liquidityPerVammLp = Math.sqrt(ptQuantityPerVammLp * lpQuantityPerVammLp);

    //time-based metrics
    const daysSincePoolCreate = vaultContext.ptLpPool > '' && "creation_timestamp" in JSON.parse(vaultContext.ptLpPool) ?
        calc_days_since_created(JSON.parse(vaultContext.ptLpPool).creation_timestamp) : 1;

    const yieldSincePoolStart = (liquidityPerVammLp - 1) * (365.25 / daysSincePoolCreate);

    const fabricateLPMessage = (walletAddress, pairAddress, pLPToken, pLPTokenAmount, lpToken, lpTokenAmount) => {
        return new MsgExecuteContract(
            walletAddress,
            pairAddress,
            {
                "provide_liquidity": {
                    "assets": [
                        {
                            "info": { "token": { "contract_addr": pLPToken } },
                            "amount": String(Math.floor(Number(pLPTokenAmount * units.MILLION))),
                        },
                        {
                            "info": { "token": { "contract_addr": lpToken } },
                            "amount": String(Math.floor(Number(lpTokenAmount * units.MILLION))),
                        },
                    ]
                }
            }
        );
    };

    const fabricateWithdrawMessage = (walletAddress, pairAddress, ptLpTokenAddress, amount) => {

        const hookMsg = {
            "withdraw_liquidity":{}
        };

        const buff = new Buffer.from(JSON.stringify(hookMsg));
        const binary_msg = buff.toString("base64");

        return new MsgExecuteContract(
            walletAddress,
            ptLpTokenAddress,
            {
                "send":{
                    "contract": pairAddress,
                    "amount": String(Math.floor(Number(amount * units.MILLION))),
                    "msg": binary_msg,
                }
            }
        );
    };

    const fabricateAllowanceMessage = (walletAddress, pairAddress, token, amount) => {
        return new MsgExecuteContract(
            walletAddress,
            token,
            {
                "increase_allowance": {
                    "spender": pairAddress,
                    "amount": String(Math.floor(Number(amount * units.MILLION))),
                }
            }
        );
    };

    const sendTransaction = useCallback(async () => {
        if (!connectedWallet) {
            return;
        }
        if (connectedWallet.network.chainID.startsWith("phoenix")) {
            alert(`Please only execute this example on Testnet`);
            return;
        }

        try {
            setIsProcessing(true)
            setTxResult(null)
            setTxError(null)

            let transactionMsg;

            if (tabIndex === 0){
                //fetch vault info
                const plpToken = JSON.parse(pool).principal_token;

                //fetch pool assets
                const poolInfo = await terra.wasm.contractQuery(poolAddress, { "pool": {} });

                const tokenX = poolInfo.assets[0].info.token.contract_addr;
                const tokenY = poolInfo.assets[1].info.token.contract_addr;

                let tokenXAmount;
                let tokenYAmount;

                if (tokenX === plpToken) {
                    tokenXAmount = plpTokenAmount === "" ? lpTokenAmount * lpPrice : plpTokenAmount;
                    tokenYAmount = lpTokenAmount === "" ? plpTokenAmount * ptPrice : lpTokenAmount;
                } else {
                    tokenXAmount = lpTokenAmount === "" ? plpTokenAmount * ptPrice : lpTokenAmount;
                    tokenYAmount = plpTokenAmount === "" ? lpTokenAmount * lpPrice : plpTokenAmount;
                }

                //sign & broadcast walletprovider-linekd wallet LP txs
                transactionMsg = {
                    msgs: [
                        fabricateAllowanceMessage(connectedWallet.walletAddress, poolAddress, tokenX, tokenXAmount),
                        fabricateAllowanceMessage(connectedWallet.walletAddress, poolAddress, tokenY, tokenYAmount),
                        fabricateLPMessage(connectedWallet.walletAddress, poolAddress, tokenX, tokenXAmount, tokenY, tokenYAmount),
                    ],
                };
            } else {

                const withdraw_amount = withdrawLpAmount === "" ? ptLpBalance / units.MILLION : withdrawLpAmount; //in millions

                transactionMsg = {
                    msgs: [
                        fabricateWithdrawMessage(connectedWallet.terraAddress, poolAddress, vaultContext.ptLpPoolTokenAddress, withdraw_amount)
                    ],
                };
            }

            //sign using connected wallet, then can pass signed payload to normal LCDclient flow
            const signed_tx = await connectedWallet.sign(transactionMsg);

            //here we are using the LCD object broadcast a wallet-signed tx payload
            const result = await terra.tx.broadcast(signed_tx.result);
            setTxResult(result);

            forceUpdate();
        } catch (error) {
            if (error instanceof UserDenied) {
                setTxError("User Denied")
            } else {
                setTxError(
                    "Unknown Error: " +
                    (error instanceof Error ? error.message : String(error))
                )
            }
        }

        setIsProcessing(false)
    }, [connectedWallet, terra, plpTokenAmount, lpTokenAmount, withdrawLpAmount, poolAddress, pool, forceUpdate, tabIndex, vaultContext.ptLpPoolTokenAddress, ptLpBalance, ptPrice, lpPrice]);

    const prevPoolRef = useRef();
    useEffect(() => {
        if (prevPoolRef.current !== pool) {
            setTxResult('')
            setTxError('')
        }
        prevPoolRef.current = pool;
    }, [pool]);

    const pl_lp_apy = pool && daysSincePoolCreate < units.VAULT_AGE_APY_THRESHOLD ? 'calculation pending ...' : 
        liquidityPerVammLp > 0 ? (100 * yieldSincePoolStart).toFixed(2) + "%" : "-%"

    return (
        <>
            {connectedWallet?.availablePost && (
                <Tabs
                    isFitted
                    variant="enclosed"
                    onChange={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab className='tab'>Provide</Tab>
                        <Tab className='tab'>Withdraw</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Stack spacing={3} mb={10}>
                                <HStack spacing={2}>
                                    <Stack spacing={1} w='50%'>
                                        <Input
                                            name="pt amount"
                                            type="text"
                                            value={plpTokenAmount}
                                            placeholder={lpTokenAmount > '' ? (lpTokenAmount * lpPrice).toFixed(6) : "PT amount"}
                                            onChange={e => isNumber(e.target.value) && setPLPTokenAmount(e.target.value)} 
                                            isDisabled={!pool || ptBalance <= 0 || lpBalance <= 0}/>
                                        <Text
                                            fontSize='xs'
                                            textAlign='right'
                                            color={colors.FADE_WHITE}>
                                            {`k: ${pool ? 
                                                    plpTokenAmount > 0 ? 
                                                        (plpTokenAmount * plpTokenAmount * liquidityPerPt*liquidityPerPt).toFixed(4)
                                                        : lpTokenAmount > '' ?
                                                            (Math.pow(lpTokenAmount * lpPrice, 2) * liquidityPerPt*liquidityPerPt).toFixed(4)
                                                            :
                                                            '-' 
                                                    : 
                                                    '-'}`}
                                        </Text>
                                    </Stack>
                                    <Stack spacing={1} w='50%'>
                                        <Input
                                            name="lp amount"
                                            type="text"
                                            value={lpTokenAmount}
                                            placeholder={plpTokenAmount > '' ? (plpTokenAmount * ptPrice).toFixed(6) : "LP amount"}
                                            onChange={e => isNumber(e.target.value) && setLPTokenAmount(e.target.value)} 
                                            isDisabled={!pool || ptBalance <= 0 || lpBalance <= 0}/>
                                        <Text
                                            fontSize='xs'
                                            textAlign='right'
                                            color={colors.FADE_WHITE}>
                                            {`k: ${pool ? 
                                                    lpTokenAmount > 0 ? 
                                                        (lpTokenAmount * lpTokenAmount * liquidityPerLP*liquidityPerLP).toFixed(4)
                                                        : plpTokenAmount > '' ?
                                                            (Math.pow(plpTokenAmount * ptPrice, 2) * liquidityPerLP*liquidityPerLP).toFixed(4)
                                                            :
                                                            '-' 
                                                    : 
                                                    '-'}`}
                                        </Text>
                                    </Stack>
                                </HStack>
                                <Flex fontSize='sm'>
                                    <Text>PT-LP APY</Text>
                                    <Spacer />
                                    <Text>{pl_lp_apy}</Text>
                                </Flex>
                                {
                                    isProcessing &&
                                    <DefaultSpinner />
                                }
                                <DefaultButton
                                    borderRadius='18px'
                                    onClick={sendTransaction}
                                    isDisabled={!pool || lpBalance <= 0 || ptBalance <= 0}>Provide liquidity</DefaultButton>
                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <Stack spacing={3} mb={10}>
                                <Input
                                    name="amount"
                                    type="text"
                                    value={withdrawLpAmount}
                                    placeholder={ptLpBalance / units.MILLION}
                                    onChange={e => isNumber(e.target.value) && setWithdrawLpAmount(e.target.value)}
                                    isDisabled={!pool || ptLpBalance <= 0}/>
                                <Text
                                    fontSize='xs'
                                    textAlign='right'
                                    color={colors.FADE_WHITE}>
                                    {`expected PT: ${pool ? 
                                        withdrawLpAmount > 0 ? 
                                            (withdrawLpAmount * ptQuantity / vaultContext.ptLpTokenSupply).toFixed(4)
                                            :
                                            ((ptLpBalance / units.MILLION) * ptQuantity / vaultContext.ptLpTokenSupply).toFixed(4)
                                        : '-'}`}
                                </Text>
                                <Text
                                    fontSize='xs'
                                    textAlign='right'
                                    color={colors.FADE_WHITE}>
                                    {`expected LP: ${pool? 
                                      withdrawLpAmount > 0 ? 
                                      (withdrawLpAmount * lpQuantity / vaultContext.ptLpTokenSupply).toFixed(4)
                                      :
                                      ((ptLpBalance / units.MILLION) * lpQuantity / vaultContext.ptLpTokenSupply).toFixed(4)
                                  : '-'}`}
                                </Text>
                                <Flex fontSize='sm'>
                                    <Text>PT-LP APY</Text>
                                    <Spacer />
                                    <Text>{liquidityPerVammLp > 0 ? (100 * yieldSincePoolStart).toFixed(2) + "%" : "-%"}</Text>
                                </Flex>
                                {
                                    isProcessing &&
                                    <DefaultSpinner />
                                }
                                <DefaultButton
                                    isDisabled={!pool || ptLpBalance <= 0}
                                    onClick={sendTransaction}
                                    borderRadius='18px'>
                                    Withdraw liquidity
                                </DefaultButton>
                            </Stack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            )}

            <TransactionFeedback
                txResult={txResult}
                txError={txError}
                connectedWallet={connectedWallet} />
        </>
    );
}